import React , {Component, Fragment} from 'react'
import {ProgressBar , Modal , Button } from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { uploadFile, resetProgress, fileListsSelected } from './../../actions/actionsArquivos'
import Dropzone from 'react-dropzone'


class UploadFiles extends Component {
  
  
constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.state = { show: false}
}

handleClose() {
        this.setState({ show: false })

      }
    
 handleShow() {
    this.props.resetProgress()
    this.setState({ show: true })
 }


  handleOnDrop = (files, rejectFiles) => {
     files.map((file, index)=>{
        this.props.fileListsSelected(file)
     })

  }

  fileUploadHandler = () =>{
    
    const files = this.props.arquivos.dataFileSelected
    files.map((file, index)=>{
        const fd = new FormData()
        fd.append('anexo', file, file.name)
        this.props.uploadFile(fd, 'UPLOAD_FILE')
    }) 

 }  

    renderDropMessage = ()=>{
      
        const files = this.props.arquivos.dataFileSelected
    
            if(files.length > 0){
                return  files.map((file, index)=>{
                    return <p key={index}>{file.name}</p>
                 }) 
            } else{
                return <p>Clique aqui para selecionar arquivos</p>
            }

    }


 


    render(){

        console.log('Testando Arquivo', this.props.arquivos)
     
        const progress = this.props.arquivos.progress 
        const filesAccept = 'image/*,application/pdf,.txt, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel'
        const enableButtonSend = this.props.arquivos.dataFileSelected.length > 0 ? '' : 'disabled' 


        return(

       <Fragment>

       <Button className="btn-attachment" variant="primary" onClick={this.handleShow}>Anexar Arquivos</Button>
          
       <Modal show={this.state.show} onHide={this.handleClose}>
           
           <Modal.Header closeButton>
              <Modal.Title>Anexo de Arquivo</Modal.Title>
           </Modal.Header>
           
           <Modal.Body>

              <div className="row">
                 <div className="col-sm-12"> 
                        {(progress > 0) && <ProgressBar now={progress} label={`${progress}%`} />}
                  </div>
              </div>

                <Dropzone accept={filesAccept}   onDrop={this.handleOnDrop}>
                {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                <section>
                    <div className="wrap-dropzone" {...getRootProps()}>
                    <input  {...getInputProps()} />
                        {this.renderDropMessage()}
                    </div>
                </section>
                )}
            </Dropzone>

                <button disabled ={enableButtonSend}  className="btn btn btn-info pull-right" onClick={this.fileUploadHandler}>Enviar Arquivo(s)</button> 


        </Modal.Body>

        <Modal.Footer>
        {
        
          <button className="btn btn-info waves-effect waves-light m-r-10" onClick={this.handleClose}>Fechar</button>
        }
          
      </Modal.Footer>
        
      </Modal>

      </Fragment>
          
          
        )
    }

}

function mapStateToProps(state){
    return {arquivos: state.file}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ uploadFile, resetProgress, fileListsSelected}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles)


