import React , {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import MainLayout   from './../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getDepartamentos, deleteDepartamento} from './../../actions/actionsDepartamentos'
import {toastr} from 'react-redux-toastr'



class ProtocolosHome extends Component {
  
    componentDidMount(){
      this.props.getDepartamentos()
    } 

    buttonActions = (cell, row) => {
      return <Fragment>
    
      <Link title="Editar" to={`/departamentos/edit/`+row.id_departamento}     type="button" className="btn btn btn-info btn-actions"><i className="fa fa-edit"></i></Link>
      <button title="Excluir" onClick={()=>{ this.confirmDelete(row.id_departamento)}} type="button" className="btn btn-danger btn-actions"><i class="fa fa-trash"></i></button>
      </Fragment>
    }


    confirmDelete = (id) =>{

      const confirmOptions = {
          onOk: () =>  this.props.deleteDepartamento(id),
          onCancel: () => console.log('CANCEL: clicked')
      };
   
      toastr.confirm('Deseja excluir esse departamento?',  confirmOptions)
   
    }   

    render(){
        

        return(

            <MainLayout title="Departamentos">   

              <div className="form-group">
                <Link to="/departamentos/adicionar" className="btn btn-info waves-effect waves-light m-r-10" >
                <i class="fa fa-plus-circle"></i> Cadastrar</Link>   
              </div>

                 <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem protocolos cadastrados'}}
                    data={ this.props.departamentos.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn  width='5%'  isKey={true} dataField='id_departamento'>Id</TableHeaderColumn>
                    <TableHeaderColumn  dataField='nome_departamento'>Nome</TableHeaderColumn>
                   <TableHeaderColumn  dataField='button'  dataAlign='center' dataFormat={this.buttonActions} >Ações</TableHeaderColumn>
              
                              
                 </BootstrapTable>
            </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {departamentos: state.departamento}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ getDepartamentos,  deleteDepartamento}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProtocolosHome)
