import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from './../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import UsuariosForm from './UsuariosForm';
import {createUser} from './../../actions/actionsUsuarios'
import {getCompanys} from './../../actions/actionsEmpresas'


class UsuariosCreate extends Component {

    componentDidMount(){
        this.props.getCompanys()
    } 

    render(){

        const empresas = this.props.empresas.data.data || []
        const redirect = this.props.usuarios.redirect
   
        if (redirect) {
            return (
                <Redirect to="/usuarios"/>
            )
        } else {

        return(
            <MainLayout title="Cadastrar Usuário">
                <UsuariosForm data={empresas} onSubmit={this.props.createUser}/>    
            </MainLayout>
        )
    }    
  }
}

function mapStateToProps(state){
    return {
        empresas: state.empresa,
        usuarios: state.user
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({createUser,getCompanys}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsuariosCreate)





