import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from './../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import EmpresasForm from './EmpresasForm';
import {showCompany, updatedCompany} from './../../actions/actionsEmpresas'
import {getDepartamentos} from './../../actions/actionsDepartamentos'

class EmpresasShow extends Component {

    componentDidMount(){
        this.props.getDepartamentos()
        const id = this.props.match.params.id
        this.props.showCompany(id)
    }

    render(){
        
        const redirect = this.props.empresas.redirect
        const departamentos = this.props.departamentos.data.data || []


        if (redirect) {
            return (
                <Redirect to="/empresas"/>
            )
        } else {
    
        return(
            <MainLayout title="Visualizar Empresa">
                <EmpresasForm  deptos={departamentos} show={true} />    
            </MainLayout>
        )
    }    
    }
}

function mapStateToProps(state){
    return {
        empresas: state.empresa, 
        departamentos: state.departamento}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({showCompany, updatedCompany, getDepartamentos}, dispatch)
}

export default connect(mapStateToProps,  mapDispatchToProps)(EmpresasShow)