import Api from './../servicedata/Api'


export default function refreshToken (dispatch) {

    Api.refreshToken().then((res) => {

        if (res){
          let itemStorage = JSON.parse(sessionStorage.getItem('userData'))
          itemStorage.data.token = res.data.token
          sessionStorage.setItem('userData',   JSON.stringify(itemStorage))
        }
    
    })
    .catch((err) => {
       if (err.response.status === 500){
          dispatch({ type:'NETWORK_ERROR'})
       }
    })
}