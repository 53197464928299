import React from 'react'
import { Field, reduxForm, formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import Masks from '../../helpers/Masks'
import Input   from '../../components/Input'
import InputMasked from '../../components/InputMasked'
import Button   from '../../components/Button'
import FormGroup  from '../../components/FormGroup'
import SelectStatus from '../../components/SelectStatus'
import { ReduxReactDatez } from 'react-datez'
import 'react-datez/dist/css/react-datez.css'
import ReduxSelectSearch from '../../components/ReduxSelectSearch'
import BASE_URL_UPLOAD from '../../servicedata/EnvUpload'
import UploadFiles from './UploadFiles'
import SelectDeptos from  './../../components/SelectDeptos'

function ProtocolosFormCreate (props) {

    const {handleSubmit} = props
    const options =  filterEmpresas() || [{}]
    const {uploadFiles, idFileSave} = props.arquivos
    props.change('arquivos_id_arquivo', idFileSave) // set value id file upload

    function filterEmpresas (){
        const empresas = props.dataempresa
        if(empresas.length ){
          return empresas.map((empresa) => {
                return {value:empresa.id_empresa, label:empresa.razao_social_empresa}  
            })
        } 
     }

     function renderSelect(){
        return(
            <Field 
            name="empresas_id_empresa" 
            options={options} 
            placeholder="Selecione uma empresa" 
            label="Empresa" 
            component={ReduxSelectSearch}/> 
        )
    }

    function renderFiles(){

        if (uploadFiles.length > 0  ) {  // if was maked upload then show all files 

            return (
                <div className="list-group">
                    {   uploadFiles.map((arquivo, index)=>{

                        return (
                            <a target="_blank" rel="noopener noreferrer" className="list-group-item" href={BASE_URL_UPLOAD+arquivo}>
                                { arquivo}    <i className="fa fa-arrow-down pull-right"> Baixar </i> 
                            </a>
                        )
                    }) 
                
                    }
                </div>
         )
        }
    }


    function renderSelectDepto(){
        const idEmpresa = props.empresas_id_empresa
        const empresas = props.dataempresa
        
        if(empresas.length > 0 && idEmpresa ){
            const current = empresas.filter((empresa) => {
                return empresa.id_empresa === idEmpresa
            })
           
            const departamentos = current[0].departamentos_empresa
           
            return(
                <Field 
                name="departamentos_id_departamento" 
                options={departamentos} 
                placeholder="Selecione uma empresa" 
                label="Departamento" 
                component={SelectDeptos}/> 
            )

        } 

          //
    }

      
        return(
        <form name="contactform" className="contactform" onSubmit={handleSubmit}>  
            
            {renderSelect()}
            {renderSelectDepto()}
        
            <Field name="numero_protocolo" label="Número do Protocolo" component={Input}/>
            <Field name="ano_protocolo" label="Ano" mask={Masks.ano} component={InputMasked}/>

            <FormGroup label="Data de Instalação">
                <Field name="data_instalacao_protocolo"  allowPast={true} component={ReduxReactDatez}/>
            </FormGroup>
            
            <FormGroup label="Melhor data para visita entre"> 
                 <Field name="data_visita_protocolo_start" allowPast={true} component={ReduxReactDatez}/>
                 <Field name="data_visita_protocolo_end"   allowPast={true} component={ReduxReactDatez}/>
            </FormGroup>

            <Field name="status_protocolo" label="Status" component={SelectStatus}/>

            <FormGroup label="Arquivos"> 
                {renderFiles()}
                <UploadFiles ></UploadFiles> 
             </FormGroup>
            
            <Button label="Salvar" />                       
  
        </form> 
 
        )
        
}

const selector = formValueSelector('protocolosFormCreate')

ProtocolosFormCreate = connect( 
    state => ({
      arquivos: state.file,
      empresas_id_empresa: selector(state, 'empresas_id_empresa'),
      nome_arquivo: selector(state, 'nome_arquivo')
    })
  )(ProtocolosFormCreate)

export default reduxForm({ form: 'protocolosFormCreate'})(ProtocolosFormCreate)
   


