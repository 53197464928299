const INITIAL_STATE =  {data:[], saveSuccess: false, redirect: false}

export default function (state = INITIAL_STATE, action){
    
    switch(action.type){
        
        case 'CREATE_EMPRESA':
            return {...state, saveSuccess: action.payload}

        case 'GET_COMPANYS':
            return {...state, data: action.payload}  
        
        case 'DELETE_COMPANY':
            return state   
        
        case 'SHOW_COMPANY':
             return {...state, data: action.payload.data}    

        case 'UPDATED_COMPANY':
            return state    
            
        case 'REDIRECT_COMPANY':
            return {...state, redirect: action.payload} 
         
        default: 
            return state
    }
}
