import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class NavCliente extends Component{
    
    render(){
       
        return( <ul className="nav" id="side-menu">

            <li><Link to="/dashboard-cliente"><i className="fa fa-home"></i> Painel de Controle</Link></li>
            <li><Link to="/protocolos-clientes"><i className="fa fa-list "></i> Protocolos</Link></li>
            <li><Link to="/perfil-cliente"><i className="fa fa-user "></i> Perfil</Link></li>
            <li><Link to="/logout-cliente"><i className="fa fa-power-off"></i> Sair </Link></li>
    
        </ul>

    )

    }

}

export default NavCliente