import React from 'react'

const Button = ({label})=>(
 
    <div className="form-group">
       <div className="col-sm-12">
            <button className="btn btn-info waves-effect waves-light m-r-10" >  <i className="fa fa-plus-circle"></i> {label}</button>
        </div>
    </div>
    
) 

export default Button