import Api from './../servicedata/Api'

export function login(user) {


    return function(dispatch) {
        Api.login(user)
        .then((res) => {
            if (res){
                sessionStorage.setItem('userData', JSON.stringify(res))
                dispatch({  type:'LOGIN', payload: res.data.user})
            }
        })
        .catch((err) => {
            dispatch({  type:'LOGIN_FAIL', payload:  err.response.data.error})
           
        })
    }
  }


export  function logout(e){
    
    sessionStorage.clear()
    return {type:'LOGOUT'}
}


export function changeValue(e){
    return {
        type:'CHANGED_VALUE_LOGIN',
        field: e.target.name,
        value: e.target.value
    }
} 