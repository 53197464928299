import React , {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import MainLayout   from './../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getCompanys, deleteCompany} from './../../actions/actionsEmpresas'
import {toastr} from 'react-redux-toastr'


class EmpresasHome extends Component {
  
  componentDidMount(){
    this.props.getCompanys()
  } 

    buttonActions= (cell, row) => {
      return <Fragment>
      <Link title="Visualizar" to={`/empresas/show/`+row.id_empresa} type="button" className="btn btn btn-success btn-actions"><i className="fa fa-eye"></i></Link>
      <Link title="Editar" to={`/empresas/edit/`+row.id_empresa}    type="button" className="btn btn btn-info btn-actions"><i className="fa fa-edit"></i></Link>
      <button title="Excluir" onClick={()=>{ this.confirmDelete(row.id_empresa)}} type="button" className="btn btn-danger btn-actions"><i className="fa fa-trash"></i></button>
      </Fragment>
    }


    confirmDelete = (id) =>{

     const confirmOptions = {
        onOk: () =>  this.props.deleteCompany(id),
        onCancel: () => console.log('CANCEL: clicked')
      };

      toastr.confirm('Deseja excluir essa empresa?',  confirmOptions)

    }  

   
    
    render(){


        return(

            <MainLayout title="Empresas">   

              <div className="form-group">
                <Link to="/empresas/adicionar" className="btn btn-info waves-effect waves-light m-r-10" > 
                <i className="fa fa-plus-circle"></i> Cadastrar</Link>   
              </div>

                <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem empresas cadastradas'}}
                    data={ this.props.empresas.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn  isKey={true} dataField='id_empresa'>Id</TableHeaderColumn>
                    <TableHeaderColumn dataField='razao_social_empresa'>Razão Social</TableHeaderColumn>
                    <TableHeaderColumn dataField='cnpj_empresa'>CNPJ/CPF</TableHeaderColumn>
                    <TableHeaderColumn dataField='telefone_empresa'>Telefone</TableHeaderColumn>
                    <TableHeaderColumn dataField='cidade_empresa'>Cidade</TableHeaderColumn>
                  
                    <TableHeaderColumn dataField='button'  dataAlign='center' dataFormat={this.buttonActions} >Ações</TableHeaderColumn> 
                              
                  </BootstrapTable>
            </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {empresas: state.empresa}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getCompanys, deleteCompany}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EmpresasHome)


