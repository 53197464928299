const INITIAL_STATE =  {
     data:[], 
     fileSelected: false, 
     dataFileSelected:[], 
     uploadFiles: [], 
     idFileSave:[], 
     progress:0, 
     fileNameUpload: false,
     isAttachment: false
}

export default function (state = INITIAL_STATE, action){
    
    switch(action.type){
        
        case 'FILE_SELECTED':
             return {...state, fileSelected: action.payload}

        case 'FILES_LIST_SELECTED':
               return {...state,  dataFileSelected: [...state.dataFileSelected,action.payload]}  
              
        case 'UPLOAD_FILE':
             return {...state, uploadFiles: [...state.uploadFiles,action.payload] , idFileSave: [...state.idFileSave,action.id]} 

        case 'PROGRESS':    
             return {...state, progress: action.payload}

         case 'RESET_PROGRESS':    
             return {...state, progress:0}

        case 'GET_FILES':
             return {...state, data: action.payload}

        case 'GET_FILES_PROTOCOL':
               return {...state, data: action.payload}  

        case 'IS_ATTACHMENT':   
                return {...state, isAttachment: action.payload} 

        case 'RESET_ATTACHMENT':   
               return {...state, isAttachment: false} 
                
        case 'RESET_FILES':  
               return {...state, data: []} 
             
        case 'RESET_UPLOAD_FILE':
                return {...state, uploadFiles: ''} 

        case 'RESET_STATE':
               return  INITIAL_STATE       
           
        default: 
            return state
    }
}