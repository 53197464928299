import React , {Component} from 'react'
import MainLayout   from './../../components/MainLayout'
import Panel from './../../components/Panel'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getInfoDashboard} from './../../actions/actionsDashboard'

class Dashboard extends Component {
   

    componentDidMount(){
        this.props.getInfoDashboard()
    } 


    welcome(){
        
        if(sessionStorage.getItem('userData')){
            const itemStorage = JSON.parse(sessionStorage.getItem('userData'))
            return   itemStorage.data.user.name
        } 
    }

    

    render(){

        const  {total_empresas , total_protocolos , total_arquivos, total_usuarios} = this.props.dashboard.data || []

        return(

        <MainLayout title="Painel">   

        <div className="alert alert-info" role="alert">
            Bem Vindo Ao <strong>Gestor</strong>Multcrop!  Você está logado como {this.welcome()} <strong></strong> 
        </div>

        <div className="row">
            <Panel label="Empresas Cadastradas"  icon="fa fa-copyright fa-5x" info={total_empresas}  link='/empresas'  type="panel panel-primary"> </Panel>
            <Panel label="Protocolos Cadastrados" icon="fa fa-file-text-o fa-5x" info={total_protocolos} link='/protocolos' type="panel panel-yellow"> </Panel>
            <Panel label="Arquivos Cadastrados"  icon="fa fa fa-file-excel-o  fa-5x" info={total_arquivos}  link='/arquivos'  type="panel panel-green"> </Panel>
            <Panel label="Usuários Cadastrados" icon="fa fa-users fa-5x" info={total_usuarios}  link='/usuarios'  type="panel panel panel-red"> </Panel>
          
        </div>

        
        </MainLayout>
        )
    }

}


function mapStateToProps(state){
    return {dashboard: state.dashboard}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getInfoDashboard}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)



