import React, {useState, useEffect} from 'react'
import NavMain from './NavMain';
import NavCliente from './NavCliente'

function Sidebar (){
    
    const [typeLogin, setTypeLogin] = useState('admin')

      useEffect(() => {
      
        if(sessionStorage.getItem('userData')){
          const userData = JSON.parse(sessionStorage.getItem('userData'))
          const userNivel = userData.data.user.nivel
          setTypeLogin(userNivel )
        }
       
      },[])

    function renderNavType(){
       const nav = (typeLogin === "admin") ? <NavMain/> : <NavCliente/>
       return nav 
    }

    return(
        <div className="navbar-default sidebar" role="navigation">
            {(renderNavType())}
        </div>
       
    )
    
}

export default Sidebar



