import {reducer as formReducer} from 'redux-form'
import { combineReducers } from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr'
import reducerEmpresas from './reducerEmpresas'
import reducerProtocolos from './reducerProtocolos'
import reducerUsuarios from './reducerUsuarios'
import reducerArquivos from './reducerArquivos'
import reducerDashboard from './reducerDashboard'
import reducerDepartamentos from './reducerDepartamentos'


const rootReducer = combineReducers({
  
    form: formReducer,
    empresa: reducerEmpresas,
    protocolo: reducerProtocolos,
    user: reducerUsuarios,
    file: reducerArquivos,
    dashboard:reducerDashboard,
    departamento:reducerDepartamentos,
    toastr: toastrReducer
  
});

export default rootReducer;