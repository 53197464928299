import React , {Component} from 'react'
import {ProgressBar } from 'react-bootstrap';
import MainLayout   from './../../components/MainLayout'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { uploadFile, resetProgress, fileListsSelected } from './../../actions/actionsArquivos'
import Dropzone from 'react-dropzone'


class ArquivosCreate extends Component {
  

  componentDidMount(){
      this.props.resetProgress()
  } 
    
  handleOnDrop = (files, rejectFiles) => {
     return files.map((file, index)=>{
        return this.props.fileListsSelected(file)
     })

  }

  fileUploadHandler = () =>{
    
    const files = this.props.arquivos.dataFileSelected
      files.map((file, index)=>{
        const fd = new FormData()
        fd.append('anexo', file, file.name)
        this.props.uploadFile(fd, 'UPLOAD_FILE')
    }) 

 }  

    renderDropMessage = ()=>{
      
        const files = this.props.arquivos.dataFileSelected
    
            if(files.length > 0){
                return  files.map((file, index)=>{
                    return <p>{file.name}</p>
                 }) 
            } else{
                return <p>Clique aqui para selecionar arquivos</p>
            }

    }


    render(){
     
        const progress = this.props.arquivos.progress 
        const filesAccept = 'image/*,application/pdf,.txt, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel'
        const enableButtonSend = this.props.arquivos.dataFileSelected.length > 0 ? '' : 'disabled' 

       

        return(

            <MainLayout title="Arquivos">   

              <div className="row">
                 <div className="col-sm-12"> 
                        {(progress > 0) && <ProgressBar now={progress} label={`${progress}%`} />}
                  </div>
              </div>

                <Dropzone accept={filesAccept}   onDrop={this.handleOnDrop}>
                {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
                <section>
                    <div className="wrap-dropzone" {...getRootProps()}>
                    <input  {...getInputProps()} />
                        {this.renderDropMessage()}
                    </div>
                </section>
                )}
            </Dropzone>


         
                <button disabled ={enableButtonSend}  className="btn btn btn-info" onClick={this.fileUploadHandler}>Enviar Arquivo(s)</button> 
           
          
          </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {arquivos: state.file}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ uploadFile, resetProgress, fileListsSelected}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ArquivosCreate)


