import React from 'react'
import ReduxToastr from 'react-redux-toastr'
import './../../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css'


const Messages = (field)=>(

    <ReduxToastr
     timeout={4000}
     newsestOnTop={false}
     preventDuplicates={true}
     positon="top-right"
     transitionIn="fadeIn"
     transitionOut="fadeOut" 
     progressBar
     />

) 

export default Messages
