import React, { Fragment, useState, useEffect} from 'react';
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import Dashboard from './../pages/dashboard/Dashboard'
import DashboardCliente from './../pages/dashboard/DashboardCliente'
import EmpresasCreate from './../pages/empresas/EmpresasCreate'
import EmpresasEdit from './../pages/empresas/EmpresasEdit'
import EmpresasShow from './../pages/empresas/EmpresasShow'
import EmpresasHome from './../pages/empresas/EmpresasHome'
import ProtocolosCreate from './../pages/protocolos/ProtocolosCreate'
import ProtocolosHome from './../pages/protocolos/ProtocolosHome'
import ProtocolosEdit from './../pages/protocolos/ProtocolosEdit'
import ProtocolosShow from './../pages/protocolos/ProtocolosShow'
import UsuariosCreate from './../pages/usuarios/UsuariosCreate'
import UsuariosHome from './../pages/usuarios/UsuariosHome'
import UsuariosEdit from './../pages/usuarios/UsuariosEdit'
import ArquivosHome from './../pages/arquivos/ArquivosHome'
import ArquivosCreate from './../pages/arquivos/ArquivosCreate'
import ProtocolosHomeCliente from './../pages/clientes/ProtocolosHomeCliente'
import Login from './../pages/login/Login'
import LoginCliente from './../pages/login/LoginCliente'
import PerfilCliente from './../pages/clientes/usuarios/PerfilCliente'
import AnexosProtocolosCliente from './../pages/clientes/protocolos/AnexosProtocolosCliente';
import DptoHome from './../pages/departamentos/DeptoHome'
import DptoCreate from './../pages/departamentos/DeptoCreate'
import DptoEdit from './../pages/departamentos/DeptoEdit'

function Admin() {

    const [typeLogin, setTypeLogin] = useState()

    useEffect(() => {
      
      if(sessionStorage.getItem('userData')){
         const userData = JSON.parse(sessionStorage.getItem('userData'))
         const userNivel = userData.data.user.nivel
         setTypeLogin(userNivel)
      } else{
       
      }
     
    },[])
  

    function renderRoutesRole(){

      if(typeLogin === "admin"){

      return (
        <Fragment>
            <Route exact path="/dashboard" component={Dashboard} /> 
            <Route exact path="/empresas" component={EmpresasHome} /> 
            <Route exact path="/empresas/adicionar" component={EmpresasCreate} />
            <Route exact path="/empresas/show/:id" component={EmpresasShow} />
            <Route exact path="/empresas/edit/:id" component={EmpresasEdit} />
            <Route exact path="/departamentos" component={DptoHome} /> 
            <Route exact path="/departamentos/adicionar" component={DptoCreate} /> 
            <Route exact path="/departamentos/edit/:id" component={DptoEdit} />
            <Route exact path="/protocolos" component={ProtocolosHome} />  
            <Route exact path="/protocolos/adicionar" component={ProtocolosCreate} /> 
            <Route exact path="/protocolos/show/:id" component={ProtocolosShow} />
            <Route exact path="/protocolos/edit/:id" component={ProtocolosEdit} />
            <Route exact path="/usuarios" component={UsuariosHome} />  
            <Route exact path="/usuarios/adicionar" component={UsuariosCreate} /> 
            <Route exact path="/usuarios/edit/:id" component={UsuariosEdit} />
            <Route exact path="/arquivos" component={ArquivosHome} /> 
            <Route exact path="/arquivos/adicionar" component={ArquivosCreate} /> 
            <Route exact path="/logout/" component={Login} /> 
        </Fragment>
        )
  
      } else{

          return (
            <Fragment>
               <Route exact path="/dashboard-cliente" component={DashboardCliente} /> 
               <Route exact path="/protocolos-clientes" component={ProtocolosHomeCliente} /> 
               <Route exact path="/perfil-cliente" component={PerfilCliente} /> 
               <Route exact path="/arquivos-cliente/show/:id" component={AnexosProtocolosCliente} />
               <Route exact path="/logout-cliente" component={LoginCliente} />  
            </Fragment>
          )
      }
   }
  

      return (
     
            <Router>
                <Switch> 
                    {renderRoutesRole()}
                  </Switch>
            </Router>

   
      )

} 

function mapStateToProps(state){
  return {users: state.user}
}

export default connect(mapStateToProps,null )(Admin)


