import Api from './../servicedata/Api'
import {toastr} from 'react-redux-toastr'
import {reset as resetForm, initialize } from 'redux-form'
import refreshToken  from './actionsToken'


export function  createDepartamento(empresa) {

    return function(dispatch) {
        Api.createDepartamento(empresa)
        .then((res) => {
           const result = (res.data.msg) ? true: false
           dispatch({ type:'CREATE_DEPARTAMENTO', payload: result })
           dispatch({ type:'REDIRECT_DEPARTAMENTOS',  payload: true})
           toastr.success("Departamento Cadastrado Com Sucesso")
           dispatch([resetForm('dptoFormCreate')]) 
        })
        .catch((err) => {
      
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
        })
    }
  }

  
export function getDepartamentos() {

    return  async function(dispatch) {
       await Api.getDepartamentos()
        .then((res) => {
            dispatch({   type:'GET_DEPARTAMENTOS', payload: res})
            dispatch({ type:'REDIRECT_DEPARTAMENTOS',  payload: false})
            
        })
        .catch((err) => {
        
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                getDepartamentos()
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch

        })
    }
}

export function deleteDepartamento(id) {

    return function(dispatch) {
        Api.deleteDepartamento(id)
        .then((res) => {
            dispatch({ type:'DELETE_DEPARTAMENTO'})
            dispatch(getDepartamentos())
            toastr.success("Departamento Excluído Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function showDepartamento(id){
    
    return dispatch =>{
            Api.showDepartamento(id)
            .then( res => {
                dispatch({ type:'SHOW_DEPARTAMENTO', payload: res})
                dispatch({ type:'REDIRECT_DEPARTAMENTOS',  payload: false})
                dispatch(initialize('dptoFormCreate', res.data))
            })
            .catch((err) => {
                const statusCode = err.response.status
                switch (statusCode) {
                  case 401:
                    refreshToken(dispatch)
                    break;
                
                  default:
                    dispatch({  type:'ERRORS', payload: err.response.data})
          
                } // end switch
            })
    }
    
}

export function updatedDepartamento(depto) {

    return function(dispatch) {
        Api. updatedDepartamento(depto.id_departamento, depto)
        .then((res) => {
            dispatch({ type:'UPDATED_DEPARTAMENTO'})
            dispatch(getDepartamentos())
            dispatch({ type:'REDIRECT_DEPARTAMENTOS',  payload: true})
            toastr.success("Departamento Atualizado Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
        })
    }

}
