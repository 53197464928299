import Api from './../servicedata/Api'
import {toastr} from 'react-redux-toastr'
import {reset as resetForm, initialize } from 'redux-form'
import refreshToken  from './actionsToken'


export function  createUser(user) {

    return function(dispatch) {
        Api.createUser(user)
        .then((res) => {
           const result = (res.data.msg) ? true: false
           dispatch({ type:'CREATE_USER', payload: result })
           dispatch({   type:'REDIRECT', payload: true })
           toastr.success("Usuário Cadastrada Com Sucesso")
           dispatch([resetForm('usuariosForm')]) 
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
        })
    }
  }


export function getUsers() {

    return  async function(dispatch) {
       await Api.getUsers()
        .then((res) => {
            dispatch({   type:'GET_USERS', payload: res})
            dispatch({   type:'REDIRECT', payload: false })
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                getUsers()
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function deleteUser(id) {

    return function(dispatch) {
        Api.deleteUser(id)
        .then((res) => {
            dispatch({ type:'DELETE_USER'})
            dispatch(getUsers())
            toastr.success("Usuário Excluído Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function showUser(id){
    
    return dispatch =>{
            Api.showUser(id)
            .then( res => {
                dispatch({ type:'SHOW_USER', payload: res})
                dispatch(initialize('usuariosForm', res.data))
            })
            .catch((err) => {
                const statusCode = err.response.status
                switch (statusCode) {
                  case 401:
                    refreshToken(dispatch)
                    break;
                
                  default:
                    dispatch({  type:'ERRORS', payload: err.response.data})
          
                } // end switch
            })
    }
    
}

export function updatedUser(user) {

    return function(dispatch) {
        Api.updatedUser(user.id, user)
        .then((res) => {
            dispatch({ type:'UPDATED_USER'})
            dispatch(getUsers())
            dispatch({   type:'REDIRECT', payload: true })
            dispatch([resetForm('usuariosForm')]) 
            toastr.success("Usuário Atualizado Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
        })
    }

}

// ========================= CLIENTE ===================================================

export function showProfileClient(id){
    
  return dispatch =>{
          Api.showUser(id)
          .then( res => {
              dispatch({ type:'SHOW_USER', payload: res})
              dispatch(initialize('usuariosForm', res.data))
          })
          .catch((err) => {
              const statusCode = err.response.status
              switch (statusCode) {
                case 401:
                  refreshToken(dispatch)
                  break;
              
                default:
                  dispatch({  type:'ERRORS', payload: err.response.data})
        
              } // end switch
          })
  }
  
}



