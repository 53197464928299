import React, { useState, useEffect} from 'react'
import {  Redirect } from 'react-router'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {login, logout, changeValue} from '../../actions/actionsLogin'


function Login (props){
    const [pathlocation, setPathLocation] = useState( props.location.pathname)
    const {changeValue} = props
    const { loginFail} = props.users
    const [isLogged, setIsLogged] = useState(false)
    const [nivelUser, setNivelUser] = useState('admin')

  
    useEffect(() => {
       checkIsLogged()
    })

    function checkIsLogged(){

        if(pathlocation  === "/logout"){
            sessionStorage.clear()
            setIsLogged(false)
            setPathLocation('/login')
        } else{
            if(sessionStorage.getItem('userData')){
                const userData = JSON.parse(sessionStorage.getItem('userData'))
                setNivelUser(userData.data.user.nivel)
                setIsLogged(true)
            }
        }
    }
  
   function handleSumit (event) {  
        event.preventDefault();
        const dataLogin = props.users.login 
        props.login(dataLogin)
 
     }


   function loginError(){
        if(loginFail){
            return (
            
                <div className="alert alert-danger" role="alert">
                    {loginFail}
                </div>
             
            )        
        }
    }

  if (isLogged && nivelUser === "admin") {

        return (
            <Redirect to="/dashboard"/>
        )
        } else {

       return(

        <div className="bg-login">    
                <div className="container">
                    <div className="login_wrapper">
                        <div className="animate form login_form">
                            <div className="login_content">
                    
                            <h1>
                                <i className="fa fa-file fa-2x"></i>
                                <strong>Gestor</strong>Multcrop
                            </h1>
                    
                                <form name="contactform" className="contactform" onSubmit={handleSumit}>
                                <input name="email" type="email" onChange={changeValue}  className="form-control" placeholder="Email"></input>
                                <input name="password" type="password" onChange={changeValue}  className="form-control" placeholder="Senha" ></input>
                                <input type="submit" className="btn btn-default submit" value="Acessar"></input>
                                {loginError()} 
                                </form>
                    
                            </div>
                        </div>
                            
                    </div>

                    
                </div>
                <span className="version">Versão 1.0</span>
            </div>

        
         )}
    
   
}
    
function mapStateToProps(state){
    return {users: state.user}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({login, logout, changeValue}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)

