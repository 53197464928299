const INITIAL_STATE =  {data:[], saveSuccess: false, redirect:false}

export default function (state = INITIAL_STATE, action){
    
    switch(action.type){
        
        case 'CREATE_PROTOCOL':
            return {...state, saveSuccess: action.payload}

        case 'GET_PROTOCOLS':
            return {...state, data: action.payload}  
        
        case 'DELETE_PROTOCOL':
            return state
         
        case 'SHOW_PROTOCOL':
             return {...state, data: action.payload.data}  

        case 'UPDATED_PROTOCOL':
            return state   
            
        case 'REDIRECT':
                return {...state, redirect: action.payload} 

        case 'GET_PROTOCOLS_CLIENT':
            return {...state, data: action.payload}
            
        default: 
            return state
    }
}