import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from './../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import UsuariosForm from './UsuariosForm';
import {showUser, updatedUser} from './../../actions/actionsUsuarios'
import {getCompanys} from './../../actions/actionsEmpresas'

class EmpresasAdicionar extends Component {

    componentDidMount(){
        this.props.getCompanys()
        const id = this.props.match.params.id
        this.props.showUser(id)
    }

    render(){
    
        const empresas = this.props.empresas.data.data || []
        const redirect = this.props.usuarios.redirect
   
        if (redirect) {
            return (
                <Redirect to="/usuarios"/>
            )
        } else {

        return(
            <MainLayout title="Editar Empresa">
                <UsuariosForm edit="true" data={empresas} onSubmit={this.props.updatedUser}/>    
            </MainLayout>
        )
    }    
}
}

function mapStateToProps(state){
    return {
        usuarios: state.user,
        empresas: state.empresa,
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({showUser, updatedUser, getCompanys}, dispatch)
}

export default connect(mapStateToProps,  mapDispatchToProps)(EmpresasAdicionar)