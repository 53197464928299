import React from 'react'

export const SelectNivelUser = (field)=>(
    
   <div className="form-group">
        <label className="col-sm-12">{field.label}</label>
        <div className="col-sm-12">
            <select  {...field.input}  className="form-control">
                <option value="0">Selecione Nível </option>
                <option value="admin">Admin</option>
                <option value="cliente">Cliente</option>
                
            </select>
        </div>
    </div>
)
export default SelectNivelUser