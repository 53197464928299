import Api from './../servicedata/Api'
import {toastr} from 'react-redux-toastr'
import { initialize } from 'redux-form'
import refreshToken  from './actionsToken'

export function fileSelected (file){
    return {
        type:'FILE_SELECTED',
        payload: file
    }
}

export function fileListsSelected (file){
    return {
        type:'FILES_LIST_SELECTED',
        payload: file
    }
}


export function resetProgress2 (){
    return {
        type:'RESET_PROGRESS'
    }
}

export function resetProgress (){
    return function(dispatch) {
        dispatch({ type:'RESET_PROGRESS'})
        dispatch({ type:'RESET_STATE'})
    }
}


export function resetFiles (){
    return {
        type:'RESET_FILES'
    }
}

export function  resetIsAttachment(){
    return {
        type:'RESET_ATTACHMENT'
    }
}

  export function uploadFile(file) {

    return function(dispatch) {
        
        const config = {onUploadProgress: (ProgressEvent) => {
            let progressData = 0;
             progressData = Math.round((ProgressEvent.loaded ) / ProgressEvent.total * 100)
                dispatch({ type:'PROGRESS', payload:   progressData})
            }
        }
        
        Api.uploadFile(file, config)
        
        .then((res) => {
           dispatch({   type: 'UPLOAD_FILE', payload: res.data.data.msg, id: res.data.data.id })
        })
        .catch((err) => {

            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                console.log('Erro', err.response)
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch

        })
    }
  }


export function getFiles() {

    return  async function(dispatch) {
       await Api.getFiles()
        .then((res) => {
            dispatch({   type:'GET_FILES', payload: res})
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                getFiles()
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function deleteFile(id) {

    return function(dispatch) {
        Api.deleteFile(id)
        .then((res) => {
            dispatch({ type:'DELETE_FILE'})
            dispatch(getFiles())
            toastr.success("Arquivo Excluído Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function showFile(id){
    
    return dispatch =>{
            Api.showFile(id)
            .then( res => {
                dispatch({ type:'SHOW_COMPANY', payload: res})
                dispatch(initialize('empresasForm', res.data))
            })
            .catch((err) => {
                const statusCode = err.response.status
                switch (statusCode) {
                  case 401:
                    refreshToken(dispatch)
                    break;
                
                  default:
                    dispatch({  type:'ERRORS', payload: err.response.data})
          
                } // end switch
            })
    }
    
}

export function updatedFile(empresa) {

    return function(dispatch) {
        Api.updatedFile(empresa.id_empresa, empresa)
        .then((res) => {
            dispatch({ type:'UPDATED_COMPANY'})
            dispatch(getFiles())
            toastr.success("Arquivo Atualizado Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }

}

export function getFilesProtocol(id) {

    return  async function(dispatch) {
       await Api.getFilesProtocol(id)
        .then((res) => {
            dispatch({ type:'RESET_FILES'})
            dispatch({ type:'GET_FILES_PROTOCOL', payload: res})
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}


export function isAttachment(id) {

    return  async function(dispatch) {
       await Api.isAttachment(id)
        .then((res) => {
         
            dispatch({ type:'IS_ATTACHMENT', payload: res})
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

