import React from 'react'
import MaskedInput from 'react-text-mask'

const InputMasked = (field)=>(
    
    <div className="form-group">
    <label className="col-sm-12">{field.label}</label>
        <div className="col-sm-12"> 
            <MaskedInput {...field.input} mask={field.mask} disabled ={(field.disabled)? "disabled" : ""}   className="form-control" />
        </div>
    </div>

)

export default InputMasked

