const INITIAL_STATE =  {data:[], saveSuccess: false,  loginSuccess:false, login:[] , loginFail:false, redirect:false}

export default function (state = INITIAL_STATE, action){
    
    switch(action.type){
        
        case 'CREATE_USER':
            return {...state, saveSuccess: action.payload}

        case 'GET_USERS':
            return {...state, data: action.payload}  
        
        case 'DELETE_USER':
            return state   
        
        case 'SHOW_USER':
             return {...state, data: action.payload.data}    

        case 'UPDATED_USER':
            return state   
            
        case 'LOGIN':
            return {...state, loginSuccess: action.payload}
        
        case 'LOGOUT':
                return {...state, loginSuccess: false}

        case 'LOGIN_FAIL':
            return {...state, loginFail: action.payload}

        case 'CHANGED_VALUE_LOGIN':
            return {...state, login: {...state.login, [action.field]: action.value}} 
             
        case 'REDIRECT':
            return {...state, redirect: action.payload} 
         
        default: 
            return state
    }
}