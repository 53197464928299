import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from './../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import ProtocolosFormShow  from './ProtocolosFormShow'
import {create, showProtocol, updatedProtocol} from './../../actions/actionsProtocolos'
import {getCompanys} from './../../actions/actionsEmpresas'

class ProtocolosShow extends Component {
  
    componentDidMount(){
        this.props.getCompanys()
        const id = this.props.match.params.id
        this.props.showProtocol(id)
    } 

    render(){
        const empresas = this.props.empresas.data.data || []
        const redirect = this.props.protocolos.redirect
        const protocolos = this.props.protocolos.data

    
            if (redirect) {
                return (
                    <Redirect to="/protocolos"/>
                )
            } else {

            return(
                
                <MainLayout title="Visualizar Protocolo">
                    <ProtocolosFormShow
                     edit="true" 
                     dataempresa={empresas} 
                     dataprotocolo={protocolos}    
                     initialValues={{arquivos_id_arquivo: null}}  
                     onSubmit={this.props.updatedProtocol}   />      
                </MainLayout>
            )
        }
    }     
}

function mapStateToProps(state){
    return {
        empresas: state.empresa,
        protocolos: state.protocolo
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({create,getCompanys, showProtocol, updatedProtocol}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolosShow)