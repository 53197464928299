import Api from './../servicedata/Api'
import {toastr} from 'react-redux-toastr'
import {reset as resetForm, initialize } from 'redux-form'
import refreshToken  from './actionsToken'


export function  create(protocolo) {

    return function(dispatch) {
        Api.createProtocol(protocolo)
   
        .then((res) => {
           const result = (res.data.msg) ? true: false
           dispatch({   type:'CREATE_PROTOCOL', payload: result })
           dispatch([resetForm('protocolosFormCreate')]) 
           dispatch({   type:'REDIRECT', payload: true })
           toastr.success("Protocolo Cadastrado Com Sucesso")
        
        })
        .catch((err) => {

            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
              err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch

        })
    } 
  }

 
export function getProtocols() {

    return  function(dispatch) {
       Api.getProtocols()
        .then((res) => {
            dispatch({   type:'GET_PROTOCOLS', payload: res})
            dispatch({   type:'REDIRECT', payload: false })
        })
        .catch((err) => {
        
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                getProtocols()
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch


        })
    }
}

  export function deleteProtocol(id) {

    return function(dispatch) {
        Api.deleteProtocol(id)
        .then((res) => {
            dispatch({ type:'DELETE_PROTOCOL'})
            dispatch(getProtocols())
            toastr.success("Protocolo Excluído Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function showProtocol(id){
    
    return dispatch =>{
            Api.showProtocol(id)
         
            .then( res => {
                dispatch({ type:'SHOW_PROTOCOL', payload: res})
                dispatch(initialize('protocolosFormEdit', res.data))
            })
            .catch((err) => {
        
                const statusCode = err.response.status
                switch (statusCode) {
                  case 401:
                    refreshToken(dispatch)
                    break;
                
                  default:
                    dispatch({  type:'ERRORS', payload: err.response.data})
          
                } // end switch
    
    
            })
    }
    
}

export function updatedProtocol(protocol) {

   return function(dispatch) {
        Api.updatedProtocol(protocol.id_protocolo, protocol)
        .then((res) => {
            dispatch({ type:'UPDATED_PROTOCOL'})
            dispatch({ type:'REDIRECT', payload: true })
            dispatch({ type:'RESET_UPLOAD_FILE'})
            dispatch(getProtocols())
            toastr.success("Protocolo Atualizado Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
           
        })
    } 

}

export function getProtocolsClient(id) {

    return  function(dispatch) {
       Api.getProtocolsClient(id)
        .then((res) => {
            dispatch({   type:'GET_PROTOCOLS_CLIENT', payload: res})
           
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}