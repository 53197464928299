import React , {Component} from 'react'
import MainLayout   from './../../components/MainLayout'
import Panel from './../../components/Panel'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getInfoDashboardCliente} from './../../actions/actionsDashboard'

class DashboardCliente extends Component {
   

    componentDidMount(){

        if(sessionStorage.getItem('userData')){
            const userData = JSON.parse(sessionStorage.getItem('userData'))
            const id_empresa =  userData.data.user.empresas_id_empresa
            this.props.getInfoDashboardCliente(id_empresa)
        }
        
    }

    welcome(){
        
        if(sessionStorage.getItem('userData')){
            const itemStorage = JSON.parse(sessionStorage.getItem('userData'))
            return   itemStorage.data.user.name
        } 
    
    }


    render(){
        const  { total_protocolos } = this.props.dashboard.data || []
   
        return(

        <MainLayout title="Dashboard">   

        <div className="alert alert-info" role="alert">
            Bem Vindo Ao <strong>Gestor</strong>Multcrop!  Você está logado como {this.welcome()}  <strong></strong> 
        </div>

        <div className="row">
            <Panel label="Meus Protocolos"  icon="fa fa-copyright fa-5x" info={total_protocolos}  link='/protocolos-clientes'  type="panel panel-primary"> </Panel>
            <Panel label="SAIR DO SISTEMA" icon="fa fa-power-off fa-5x" info='logout' link='/logout-cliente' type="panel panel-green"> </Panel>
        </div>
        
        </MainLayout>
        )
    }

}


function mapStateToProps(state){
    return {dashboard: state.dashboard}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getInfoDashboardCliente}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardCliente)




