import React , {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import MainLayout   from './../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getProtocols,  deleteProtocol} from './../../actions/actionsProtocolos'
import {toastr} from 'react-redux-toastr'

class ProtocolosHome extends Component {
  
    componentDidMount(){
      this.props.getProtocols()
    } 

    buttonActions = (cell, row) => {
      return <Fragment>
      <Link title="Visualizar" to={`/protocolos/show/`+row.id_protocolo}  type="button" className="btn btn btn-success btn-actions"><i className="fa fa-eye"></i></Link>
      <Link title="Editar" to={`/protocolos/edit/`+row.id_protocolo}     type="button" className="btn btn btn-info btn-actions"><i className="fa fa-edit"></i></Link>
      <button title="Excluir" onClick={()=>{ this.confirmDelete(row.id_protocolo)}} type="button" className="btn btn-danger btn-actions"><i class="fa fa-trash"></i></button>
      </Fragment>
    }

    dateVisite = (cell, row)=>{
       return <p>  {row.data_visita_protocolo_start } e {row.data_visita_protocolo_end }</p>
    }

    confirmDelete = (id) =>{

      const confirmOptions = {
          onOk: () =>  this.props.deleteProtocol(id),
          onCancel: () => console.log('CANCEL: clicked')
      };
   
      toastr.confirm('Deseja excluir esse protocolo?',  confirmOptions)
   
    }   

    render(){
      
        return(

            <MainLayout title="Protocolos">   

              <div className="form-group">
                <Link to="/protocolos/adicionar" className="btn btn-info waves-effect waves-light m-r-10" >
                <i class="fa fa-plus-circle"></i> Cadastrar</Link>   
              </div>

                <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem protocolos cadastrados'}}
                    data={ this.props.protocolos.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn  width='5%'  isKey={true} dataField='id_protocolo'>Id</TableHeaderColumn>
                    <TableHeaderColumn  dataField='razao_social_empresa'>Empresa</TableHeaderColumn>
                    <TableHeaderColumn  dataField='numero_protocolo'>Número</TableHeaderColumn>
                    <TableHeaderColumn  dataField='status_protocolo'>Status</TableHeaderColumn>
                    <TableHeaderColumn  dataFormat={this.dateVisite}  >Data visita entre:</TableHeaderColumn>  
                    <TableHeaderColumn  dataField='updated_at'>Atualizado em:</TableHeaderColumn>    
                    <TableHeaderColumn  dataField='button'  dataAlign='center' dataFormat={this.buttonActions} >Ações</TableHeaderColumn>
              
                              
                  </BootstrapTable>
            </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {protocolos: state.protocolo}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ getProtocols,  deleteProtocol}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProtocolosHome)


