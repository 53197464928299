import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from './../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import ProtocolosFormCreate from './ProtocolosFormCreate';
import {create} from './../../actions/actionsProtocolos'
import {getCompanys} from './../../actions/actionsEmpresas'

class ProtocolosCreate extends Component {
  
    componentDidMount(){
        this.props.getCompanys()
    } 

    render(){
        const empresas = this.props.empresas.data.data || []
        const redirect = this.props.protocolos.redirect

            if (redirect) {
                return (
                    <Redirect to="/protocolos"/>
                )
            } else {

            return(
                
                <MainLayout title="Cadastrar Protocolo">
                    <ProtocolosFormCreate  initialValues={{arquivos_id_arquivo: null}}  dataempresa={empresas} onSubmit={this.props.create}   />      
                </MainLayout>
            )
        }
    }          
}

function mapStateToProps(state){
    return {
        empresas: state.empresa,
        protocolos: state.protocolo
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({create,getCompanys}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolosCreate)