import React , {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import MainLayout   from './../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getUsers, deleteUser} from './../../actions/actionsUsuarios'
import {toastr} from 'react-redux-toastr'


class UsuariosHome extends Component {
  
  componentDidMount(){
    this.props.getUsers()
  } 

    buttonAction = (cell, row) => {
        return <Fragment>
        <Link title="Editar" to={`/usuarios/edit/`+row.id}    type="button" className="btn btn btn-info btn-actions"><i className="fa fa-edit"></i> </Link>
        <button title="Excluir" onClick={()=>{ this.confirmDelete(row.id)}} type="button" className="btn btn-danger btn-actions"><i className="fa fa-trash"></i></button>
        </Fragment>
    }


      confirmDelete = (id) =>{

        const confirmOptions = {
            onOk: () =>  this.props.deleteUser(id),
            onCancel: () => console.log('CANCEL: clicked')
        };
     
        toastr.confirm('Deseja excluir esse arquivo?',  confirmOptions)
     
      }


    render(){


        return(

            <MainLayout title="Usuários">   

              <div className="form-group">
                <Link to="/usuarios/adicionar" className="btn btn-info waves-effect waves-light m-r-10" >Cadastrar</Link>   
              </div>

                <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem usuários cadastrados'}}
                    data={ this.props.usuarios.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn  isKey={true} dataField='id'>Id</TableHeaderColumn>
                    <TableHeaderColumn  dataField='name'>Nome</TableHeaderColumn>
                    <TableHeaderColumn  dataField='nome_fantasia_empresa'>Empresa</TableHeaderColumn>
                    <TableHeaderColumn  dataField='email'>Email</TableHeaderColumn>
                    <TableHeaderColumn  dataField='nivel'>Nível</TableHeaderColumn>
                    <TableHeaderColumn  dataField='count_sign_in'>Acessos</TableHeaderColumn>
                    <TableHeaderColumn  dataField='button'  dataAlign='center' dataFormat={this.buttonAction} >Ações</TableHeaderColumn>
                   
                              
                  </BootstrapTable>
            </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {usuarios: state.user}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getUsers, deleteUser}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(UsuariosHome)


