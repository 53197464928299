import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from '../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import  DeptoFormCreate from './DeptoFormCreate';
import {showDepartamento,  updatedDepartamento} from '../../actions/actionsDepartamentos'


class DeptoEdit extends Component {
    
    componentDidMount(){
        const id = this.props.match.params.id
        this.props.showDepartamento(id)
    }

    render(){
     
        const redirect = this.props.departamentos.redirect

            if (redirect) {
                return (
                    <Redirect to="/departamentos"/>
                )
            } else {

            return(
                
                <MainLayout title="Editar Departamento">
                    <DeptoFormCreate  onSubmit={this.props.updatedDepartamento}   />      
                </MainLayout>
            )
        }
    }          
}

function mapStateToProps(state){
    return {
        departamentos: state.departamento
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({showDepartamento,  updatedDepartamento}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeptoEdit)