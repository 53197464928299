import React from 'react'

export const SelectDeptos = (field)=>(


   <div className="form-group">
        <label className="col-sm-12">{field.label}</label>
        <div className="col-sm-12">
            <select  {...field.input} disabled ={(field.disabled)? "disabled" : ""}  className="form-control">
            <option value="0">Selecione Departamento </option>
            {
                field.options.map((departamento) => {
                    return <option key={departamento.id_departamento} 
                    value={departamento.id_departamento}>{departamento.nome_departamento} </option>
                    
                })
                 
            }
                  
            </select>
        </div>
    </div>
)
export default SelectDeptos 

