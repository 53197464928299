import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class NavMain extends Component{
    
    constructor(props){
        super(props)
    
        this.state = {
            toggleMenu1: false,
            toggleMenu2: false,
            toggleMenu3: false,
            }
    }

    handleMenu = (event)=>{
       
        switch(event.target.name){
            case 'menu1':
                this.setState({ toggleMenu1 : !this.state.toggleMenu1, 
                    toggleMenu2 : false,
                    toggleMenu3 : false,
                 });
                break;
            case 'menu2':
                this.setState({  toggleMenu2 : !this.state.toggleMenu2 , 
                    toggleMenu1 : false,
                    toggleMenu3 : false,
                });
                break;

            case 'menu3':
            this.setState({  toggleMenu3 : !this.state.toggleMenu3,
            
                    toggleMenu2 : false,
                    toggleMenu1 : false,
            });
            break;   

            default:    
        }

    }

    render(){
       
        return( <ul className="nav" id="side-menu">

            <li><Link to="/dashboard"><i className="fa fa-home"></i> Painel de Controle</Link></li>
            <li><Link to="/departamentos"><i className="fa fa-building "></i> Departamentos</Link></li>
            <li><Link to="/empresas"><i className="fa fa-copyright "></i> Empresas</Link></li>
            <li><Link to="/protocolos"><i className="fa fa-list "></i> Protocolos</Link></li>
            <li><Link to="/arquivos"><i className="fa fa-file-excel-o "></i> Arquivos</Link></li>
            <li><Link to="/usuarios"><i className="fa fa-users"></i> Usuários </Link></li>
            <li><Link to="/logout"><i className="fa fa-power-off"></i> Sair </Link></li>
    
        </ul>

    )

    }

}


export default NavMain