import React,{Component} from 'react'

class NavbarTop extends Component{
    render(){
        return(
        
        <nav className="navbar2 navbar-default navbar-static-top m-b-0">
            <div className="navbar-header"> 
                <div className="top-left-part">
                    <i className="fa fa-file fa-2x"></i>
                    <span className="hidden-xs"><strong>Gestor</strong>Multcrop</span>
                </div>
                <ul className="nav navbar-top-links navbar-right pull-right"></ul>
          </div>
        
            
         </nav>
        )
    }
} 
export default  NavbarTop