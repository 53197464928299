import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from '../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import  DeptoFormCreate from './DeptoFormCreate';
import {createDepartamento} from '../../actions/actionsDepartamentos'


class DeptoCreate extends Component {
  
    render(){
     
        const redirect = this.props.departamentos.redirect

            if (redirect) {
                return (
                    <Redirect to="/departamentos"/>
                )
            } else {

            return(
                
                <MainLayout title="Cadastrar Departamento">
                    <DeptoFormCreate  onSubmit={this.props.createDepartamento}   />      
                </MainLayout>
            )
        }
    }          
}

function mapStateToProps(state){
    return {
        departamentos: state.departamento
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({createDepartamento}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeptoCreate)