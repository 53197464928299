import React  from 'react'
import { Field, reduxForm, formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import Masks from './../../helpers/Masks'
import Input   from './../../components/Input'
import InputMasked from './../../components/InputMasked'
import FormGroup  from './../../components/FormGroup'
import SelectStatus from './../../components/SelectStatus'
import { ReduxReactDatez} from 'react-datez'
import 'react-datez/dist/css/react-datez.css'
import ReduxSelectSearch from './../../components/ReduxSelectSearch'
import BASE_URL_UPLOAD from './../../servicedata/EnvUpload'
import SelectDeptos from  './../../components/SelectDeptos'


function ProtocolosFormShow (props) {

    const {handleSubmit} = props
    const options =  filterEmpresas() || [{}]
    const {uploadFiles, idFileSave} = props.arquivos
    const arquivos = props.dataprotocolo.arquivos || []  
    props.change('arquivos_id_arquivo', idFileSave) // set value id file upload


    function filterEmpresas (){
        const empresas = props.dataempresa
        if(empresas.length ){
          return empresas.map((empresa) => {
                return {value:empresa.id_empresa, label:empresa.razao_social_empresa}  
            })
        } 
     }


    function getEmpresaDefault(){
        const empresas = props.dataempresa
        const idEmpresa = props.dataprotocolo.empresas_id_empresa
    
        if(empresas.length > 0 && idEmpresa ){
            
            const current = empresas.filter((empresa) => {
                return empresa.id_empresa === idEmpresa
            })
           return  {value:current[0].id_empresa, label:current[0].razao_social_empresa}  
        } 
       
     }

    function renderSelectEdit(){

         if ( getEmpresaDefault()){

            return(
                <Field 
                name="empresas_id_empresa" 
                isDisabled={true}
                disabled="disabled"
                options={options} 
                placeholder="Selecione uma empresa" 
                label="Empresa" 
                default={getEmpresaDefault()}
                component={ReduxSelectSearch}/> 
            )
            
         } 
     }

     function renderSelectDepto(){
        const idEmpresa = props.dataprotocolo.empresas_id_empresa
        const empresas = props.dataempresa
        const listaDepartamentosDefault =  props.dataprotocolo.departamentos_empresa
     
        
        if(empresas.length > 0 && idEmpresa ){
           
            const current = empresas.filter((empresa) => {
                return empresa.id_empresa === idEmpresa
            })
           
            const departamentos = current[0].departamentos_empresa
           
            return(
                <Field 
                disabled={true}
                name="departamentos_id_departamento" 
                options={departamentos} 
                placeholder="Selecione uma empresa" 
                defaultValue={listaDepartamentosDefault}
                label="Departamento" 
                component={SelectDeptos}/> 
            )

        } 

    
    }



    function renderFile(){
  
        if (arquivos && uploadFiles.length === 0  ) {
            return (
                <div className="list-group">
                    {   arquivos.map((arquivo, index)=>{

                        return (
                            <a target="_blank" rel="noopener noreferrer" className="list-group-item" href={BASE_URL_UPLOAD+ arquivo.nome_arquivo}>
                            { arquivo.nome_arquivo}    <i className="fa fa-arrow-down pull-right"> Baixar </i> 
                            </a>
                        )
                    }) 
                
                    }
                </div>
         )
        } else if(uploadFiles.length > 0 ){
            return (
                <div className="list-group">
                    {   uploadFiles.map((arquivo, index)=>{

                        return (
                            <a target="_blank" rel="noopener noreferrer" className="list-group-item" href={BASE_URL_UPLOAD+ arquivo}>
                             { arquivo}    <i className="fa fa-arrow-down pull-right"> Baixar </i> 
                            </a>
                        )
                    }) 
                
                    }
                </div>
         )
        }
    }

        return(
        <form name="contactform" className="contactform" onSubmit={handleSubmit}>  
            
            { renderSelectEdit()}
            {renderSelectDepto()}
        
            <Field  disabled={true} name="numero_protocolo" label="Número do Protocolo" component={Input}/>
            <Field  disabled={true} name="ano_protocolo" label="Ano" mask={Masks.ano} component={InputMasked}/>

            <FormGroup label="Data de Instalação">
                 <Field className="date-show"  name="data_instalacao_protocolo"  component={ReduxReactDatez}/>
             </FormGroup>
            
            <FormGroup label="Melhor data para visita"> 
                 <Field className="date-show" name="data_visita_protocolo_start" component={ReduxReactDatez}/>
                 <Field className="date-show" name="data_visita_protocolo_end"   component={ReduxReactDatez}/>
            </FormGroup>

            <Field disabled="disabled" name="status_protocolo" label="Status" component={SelectStatus}/>

            <FormGroup label="Arquivos"> 
                {renderFile()}
             </FormGroup>
            
                            
  
        </form> 
 
        )
        
}

const selector = formValueSelector('ProtocolosFormShow')



ProtocolosFormShow = connect( 

    state => ({
      arquivos: state.file,
      empresas_id_empresa: selector(state, 'empresas_id_empresa'),
      nome_arquivo: selector(state, 'nome_arquivo')
    })
  )(ProtocolosFormShow)

export default reduxForm({ form: 'protocolosFormEdit'})(ProtocolosFormShow)
   


