import React, {Component} from 'react'
import {  Redirect } from 'react-router'
import MainLayout   from './../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import EmpresasForm from './EmpresasForm';
import {create} from './../../actions/actionsEmpresas'
import {getDepartamentos} from './../../actions/actionsDepartamentos'

class EmpresasAdicionar extends Component {

    componentDidMount(){
        this.props.getDepartamentos()
    }

    render(){
      
        const departamentos = this.props.departamentos.data.data || []
        const redirect = this.props.empresas.redirect

        if (redirect) {
            return (
                <Redirect to="/empresas"/>
            )
        } else {

            return(
                <MainLayout title="Cadastrar Empresa">
                    <EmpresasForm create="true" deptos={departamentos}  onSubmit={this.props.create}/>    
                </MainLayout>
            )
    }    }    
}


function mapStateToProps(state){
    return {
        empresas: state.empresa,
        departamentos: state.departamento
      
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({create, getDepartamentos}, dispatch)
}

export default connect( mapStateToProps, mapDispatchToProps)(EmpresasAdicionar )





