import React , {Component} from 'react'
import MainLayout   from './../../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getFilesProtocol, deleteFile, resetFiles } from './../../../actions/actionsArquivos'
import BASE_URL_UPLOAD from './../../../servicedata/EnvUpload'


class AnexosProtocolosCliente extends Component {
  
  componentDidMount(){
    const id = this.props.match.params.id
    this.props.getFilesProtocol(id)
  } 

    buttonEdit = (cell, row) => {
        const linkFile= BASE_URL_UPLOAD +row.nome_arquivo
        return <a target="_blank" href={linkFile} rel="noopener noreferrer"   
        type="button" className="btn btn btn-info"><i className="fa fa-chevron-circle-down"></i> Baixar </a>
    }

    render(){

        return(

            <MainLayout title="Arquivos">   

            
                <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem arquivos cadastradas'}}
                    data={ this.props.arquivos.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn width='2%' isKey={true} dataField='id_arquivo'>Id</TableHeaderColumn>
                    <TableHeaderColumn width='20%' dataField='nome_arquivo'>Nome do Arquivo</TableHeaderColumn>
                    <TableHeaderColumn width='7%'  dataField='button'  dataAlign='center' dataFormat={this.buttonEdit} >Baixar</TableHeaderColumn>
                  
                              
                  </BootstrapTable>
            </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {arquivos: state.file}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getFilesProtocol, deleteFile, resetFiles}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AnexosProtocolosCliente)


