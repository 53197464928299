import React from 'react'
import { Field, reduxForm, formValueSelector} from 'redux-form'
import Input   from '../../components/Input'
import Button   from '../../components/Button'
import 'react-datez/dist/css/react-datez.css'


function DeptoFormCreate (props) {

    const {handleSubmit} = props
  
        return(
        <form name="contactform" className="contactform" onSubmit={handleSubmit}>  
            <Field name="nome_departamento" label="Nome do Departamento" component={Input}/>
            <Button label="Salvar" />                       
        </form> 
 
        )
        
}

const selector = formValueSelector('dptoFormCreate')

export default reduxForm({ form: 'dptoFormCreate'})(DeptoFormCreate)
   


