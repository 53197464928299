import React , {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import MainLayout   from './../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getFiles, deleteFile,  isAttachment, resetIsAttachment} from './../../actions/actionsArquivos'
import {toastr} from 'react-redux-toastr'
import BASE_URL_UPLOAD from './../../servicedata/EnvUpload'


class ArquivosHome extends Component {
  
  componentDidMount(){
    this.props.getFiles()
  } 

    buttonActions = (cell, row) => {
       
        const linkFile= BASE_URL_UPLOAD+row.nome_arquivo 
        
        return <Fragment> 
          <a target="_blank" title="Baixar"  href={linkFile} rel="noopener noreferrer"  type="button" className="btn btn btn-info btn-actions"><i class="fa fa-download"></i> </a>
          <button title="Excluir"  onClick={()=>{ this.confirmDelete(row.id_arquivo)}} type="button" className="btn btn-danger btn-actions"><i className="fa fa-trash"></i> </button>
        </Fragment>

    }



     confirmDelete = async (id) =>{
        
         await this.props.isAttachment(id)
         const result = this.props.arquivos.isAttachment
         const confirmOptions = {
            onOk: () =>  this.props.deleteFile(id),
            onCancel: () => console.log('CANCEL: clicked')
          }

        if(result){
            toastr.confirm('Existe protocolo com esse arquivo anexado.Confirma exclusão?',  confirmOptions)
            this.props.resetIsAttachment()
         
        } else{
            toastr.confirm('Deseja excluir esse arquivo?',  confirmOptions)
        }
      } 

    render(){

        return(

            <MainLayout title="Arquivos">   

            <div className="form-group">
                <Link to="/arquivos/adicionar" className="btn btn-info waves-effect waves-light m-r-10" >
                <i class="fa fa-plus-circle"></i> Enviar Arquivos</Link>   
            </div>

        
                <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem arquivos cadastradas'}}
                    data={ this.props.arquivos.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn  isKey={true} dataField='id_arquivo'>Id</TableHeaderColumn>
                    <TableHeaderColumn  dataField='nome_arquivo'>Nome do Arquivo</TableHeaderColumn>
                    <TableHeaderColumn   dataField='button'  dataAlign='center' dataFormat={this.buttonActions} >Ações</TableHeaderColumn>
                 
                              
                  </BootstrapTable>
            </MainLayout>
        )
    }

}

function mapStateToProps(state){
    return {arquivos: state.file}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getFiles, deleteFile, isAttachment, resetIsAttachment}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ArquivosHome)


