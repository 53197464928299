import React, {useState} from 'react'
import Select from 'react-select';


export default function ReduxSelectSearch (props){

    const initialValue = (props.default)? props.default: ''
    const[empresa, setEmpresa] = useState(initialValue)
  
    const handleSelectChange = (empresa) => {
      const idEmpresa = empresa.value
      setEmpresa(empresa)   
      props.input.onChange(idEmpresa) // set value in Field Form redux
       
   }

    return(
    <div className="form-group">
    <label className="col-sm-12">{props.label}</label>
        <div className="col-sm-12"> 
      
           <Select  
            defaultValue={empresa}
            options={props.options} 
            isDisabled={props.isDisabled}
            onChange={(empresa)=>handleSelectChange(empresa)}  
            placeholder="Selecione uma empresa" />  
         
        </div>
       
    </div>

)
   

}

  
    


