import React , {Component} from 'react'
import {Link} from 'react-router-dom'
import MainLayout   from './../../components/MainLayout'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { getProtocols, getProtocolsClient, deleteProtocol} from './../../actions/actionsProtocolos'
import {  resetFiles } from './../../actions/actionsArquivos'

class ProtocolosHomeCliente extends Component {
  
  componentDidMount(){
    this.props.resetFiles()
    const userData = JSON.parse(sessionStorage.getItem('userData'))
    const id_empresa =  userData.data.user.empresas_id_empresa
    this.props.getProtocolsClient(id_empresa)
  } 

  
    buttonFile = (cell, row) => {

        return <Link title="Baixar" to={`/arquivos-cliente/show/`+row.id_protocolo}    
        type="button" className="btn btn btn-info"><i className="fa fa-download"></i> </Link>
    }

    dateVisite = (cell, row)=>{
        return <p>  {row.data_visita_protocolo_start } e {row.data_visita_protocolo_end }</p>
     }


    render(){
   
        return(

            <MainLayout title="Protocolos">   

                <BootstrapTable  ref='table' 
                    options={{noDataText:'Não existem empresas cadastradas'}}
                    data={ this.props.protocolos.data.data}
                    pagination={ true }
                    searchPlaceholder={"Filtrar"}
                    search={ true }>

                    <TableHeaderColumn width="5%"  isKey={true} dataField='id_protocolo'>Id</TableHeaderColumn>
                    <TableHeaderColumn  dataField='razao_social_empresa'>Empresa</TableHeaderColumn>
                    <TableHeaderColumn  dataField='numero_protocolo'>Protocolo</TableHeaderColumn>
                    <TableHeaderColumn  dataField='data_instalacao_protocolo'>Instalação</TableHeaderColumn>
                    <TableHeaderColumn  dataFormat={this.dateVisite}  >Data visita entre</TableHeaderColumn>  
                    <TableHeaderColumn  dataField='status_protocolo'>Status</TableHeaderColumn>
                    <TableHeaderColumn  dataField='updated_at'>Atualizado</TableHeaderColumn>  
                    <TableHeaderColumn  dataField='button'  dataAlign='center' dataFormat={this.buttonFile} >Arquivos</TableHeaderColumn>  
                       
                  </BootstrapTable>
            </MainLayout>
        )
    }

}



function mapStateToProps(state){
    return {
        protocolos: state.protocolo,
        usuarios: state.user
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ getProtocols,  deleteProtocol, getProtocolsClient,  resetFiles }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProtocolosHomeCliente)


