import React from 'react'

export const SelectStatus = (field)=>(
    
   <div className="form-group">
        <label className="col-sm-12">{field.label}</label>
        <div className="col-sm-12">
            <select  {...field.input} disabled ={(field.disabled)? "disabled" : ""}  className="form-control">
                <option value="0">Selecione Status </option>
                <option value="Previsto">Previsto</option>
                <option value="Implantado">Implantado</option>
                <option value="Laudo">Laudo</option>
                <option value="Concluído">Concluído</option>
            </select>
        </div>
    </div>
)
export default SelectStatus