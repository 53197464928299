import React from 'react'

const Input = (field)=>(

    <div className="form-group">
        <label className="col-sm-12">{field.label}</label>
            <div className="col-sm-12"> 
                <input 
                type={(field.type)?field.type:"text"} 
                {...field.input}  
                multiple={(field.multiple)? " multiple" : ""}    
                disabled ={(field.disabled)? "disabled" : ""}    
                className="form-control"  />
                {field.meta.touched && field.meta.error &&<span className="error">{field.meta.error}</span>}
            </div>
    </div>
    
) 
export default Input

