import React from 'react'
import { Field, reduxForm , formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import Input   from './../../../components/Input'
import Button   from './../../../components/Button'
import 'react-datez/dist/css/react-datez.css'
import ReduxSelectSearch from './../../../components/ReduxSelectSearch'


function PerfilClienteForm (props) {
    
    const {handleSubmit} = props
    const options =  filterEmpresas() || [{}]
 
    function filterEmpresas (){
        const empresas = props.data
        if(empresas.length){
          return empresas.map((empresa) => {
                return {value:empresa.id_empresa, label:empresa.razao_social_empresa}  
            })
        } 
     }

     function getEmpresaDefault(){
        const empresas = props.data
        const idEmpresa = props.empresas_id_empresa

        if(empresas.length > 0 && idEmpresa ){
            const current = empresas.filter((empresa) => {
                return empresa.id_empresa === idEmpresa
            })
           return  {value:current[0].id_empresa, label:current[0].razao_social_empresa}  
        } 
       
     }

     function renderSelectEdit(){
        if ( getEmpresaDefault()){
           return(
               <Field 
               isDisabled={true}
          
               name="empresas_id_empresa" 
               options={options} 
               placeholder="Selecione uma empresa" 
               label="Empresa" 
               default={getEmpresaDefault()}
               component={ReduxSelectSearch}/> 
           )
           
        } 
    }


        return(
            
        <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
            <div className="row">
                <div className="col-md-6">
                     {renderSelectEdit()}
                    <Field name="name" label="Nome" component={Input}/>
                    <Field disabled name="email" label="Email" component={Input}/>
                    <Field name="password" type="password" label="Senha" component={Input}/>
                    <Field name="repeat-password" type="password" label="Repetir Senha" component={Input}/>

                    <Button label="Atualizar"/>    
                    
                 </div>

            </div>
        </form> 
 
        )
  
        
}


const selector = formValueSelector('usuariosForm')

PerfilClienteForm = connect(
    state => ({
      empresas_id_empresa: selector(state, 'empresas_id_empresa'),
    
    })
  )(PerfilClienteForm)

export default reduxForm({ form: 'usuariosForm'})(PerfilClienteForm)



   


