import React from 'react'
import { Provider } from 'react-redux'
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom'
import Admin from './routes/'
import configureStore from './store/configureStore'
import Login from './pages/login/Login'
import LoginCliente from './pages/login/LoginCliente'


function App() {

  const store = configureStore()

  return (
    <Provider  store={store}>
        <Router >
            <Switch> 
                <Route exact path="/" component={Login} /> 
                <Route exact path="/login" component={Login} /> 
                <Route exact path="/logout" component={Login} /> 
                <Route exact path="/login-cliente" component={LoginCliente} /> 
                <Route exact path="/logout-cliente" component={LoginCliente} /> 
                <Route exact component={Admin} /> 
            </Switch>
        </Router>

    </Provider>
  )
}

export default App;
