import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';
import { Field, reduxForm } from 'redux-form'
import Input   from './../../components/Input'
import Button   from './../../components/Button'
import InputMasked  from './../../components/InputMasked'
import StatesBR from './../../components/StatesBR'
import Masks from './../../helpers/Masks'


class EmpresasForm extends Component {
    constructor(props) {
        super(props);
        this.state = {listaDeptoSelected: []};
      }

    disabledComponent = ()=>{
        const { show} = this.props
        return (show)?true:false
    }  


   listaDepartamentos = ()=>{
        
        const {deptos} = this.props
        const ids = Object.keys(deptos)
    
        const departamentos =  ids.map((id)=>{
            return {id_departamento:deptos[id].id_departamento, nome_departamento:deptos[id].nome_departamento} 
          })
          
  
        return departamentos
     }


     renderMultiSelect = ()=>{    
      const {show, create,  empresas} = this.props
      const listaDepartamentos =   this.listaDepartamentos()  || []
      const listaDepartamentosDefault = empresas.data.departamentos_empresa

      if (listaDepartamentosDefault ){

        return (
          <div className="form-group">
              <label className="col-sm-12">Departamentos</label>
              <div className="col-sm-12"> 
                  <Multiselect  messages={{ emptyList: 'Não existem itens na lista' }}  
                  disabled={show?true:false} 
                  onChange={value => this.handleMultiSelect(value)}
                  name="vinculacoes"  
                  textField='nome_departamento'
                  valueField='id_departamento'
                  defaultValue={listaDepartamentosDefault}
                  placeholder="Selecione Departamentos" data={listaDepartamentos} />
                
              </div>
          </div>
        )
      } else if (listaDepartamentosDefault === 0 || create ){
        
        return (
          <div className="form-group">
          <label className="col-sm-12">Departamentos</label>
           <div className="col-sm-12"> 
              <Multiselect  messages={{ emptyList: 'Não existem itens na lista' }}   
              disabled={show?true:false}
              onChange={value => this.handleMultiSelect(value)}
              name="vinculacoes"  
              textField='nome_departamento'
              valueField='id_departamento'
              placeholder="Selecione Departamentos" data={listaDepartamentos} />
            
           </div>
      </div>
        )
      }
  
    }


    handleMultiSelect = (value)=>{    
        this.setState({
          listaDeptoSelected:  value
        })

    }

    render(){
        
    const {handleSubmit, edit} = this.props
    this.props.change('departamentos_empresa', this.state.listaDeptoSelected ) // set value departamentos selected


    return(
            
  
        <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
            <div className="row">
                <div className="col-md-6">
                    <Field name="razao_social_empresa" label="Razão Social" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="cnpj_empresa" label="CNPJ" mask={Masks.cnpj} disabled={this.disabledComponent()} component={InputMasked}/>
                    <Field name="nome_fantasia_empresa" label="Nome de Fantasia" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="email_empresa" label="Email" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="responsavel_empresa" label="Responsável" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="telefone_empresa" label="Telefone" disabled={this.disabledComponent()} mask={Masks.telefone} component={InputMasked}/>
                    <Field name="celular_empresa" label="Celular" disabled={this.disabledComponent()}  mask={Masks.celular} component={InputMasked}/>
                    
                 </div>

                 <div className="col-md-6">
                    <Field name="endereco_empresa" label="Endereço" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="bairro_empresa" label="Bairro" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="cidade_empresa" label="Cidade" disabled={this.disabledComponent()} component={Input}/>
                    <Field name="estado_empresa" label="Estado" disabled={this.disabledComponent()} component={StatesBR}/>
                  

                    {this.renderMultiSelect()}
               

                
                 </div>

                 <div className="col-md-12"> 
                     {this.disabledComponent() === false && <Button label={(edit)==="true" ? "Atualizar": "Salvar"}/>   }   
                 </div>
            </div>
        </form> 
 
        )
    }        
}




EmpresasForm = connect( 
    state => ({
      empresas: state.empresa,
     
    })
  )(EmpresasForm)

export default reduxForm({ form: 'empresasForm'})(EmpresasForm)

   


