import Api from './../servicedata/Api'
import {toastr} from 'react-redux-toastr'
import {reset as resetForm, initialize } from 'redux-form'
import refreshToken  from './actionsToken'


export function  create(empresa) {

    return function(dispatch) {
  
        Api.createCompany(empresa)
        .then((res) => {
           const result = (res.data.msg) ? true: false
           dispatch({ type:'CREATE_EMPRESA', payload: result })
           dispatch({ type:'REDIRECT_COMPANY',  payload: true})
           toastr.success("Empresa Cadastrada Com Sucesso")
           dispatch([resetForm('empresasForm')]) 
        })
        .catch((err) => {
      
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
        })
    }
  }

  
export function getCompanys() {

    return  async function(dispatch) {
       await Api.getCompanys()
        .then((res) => {
            dispatch({   type:'GET_COMPANYS', payload: res})
            dispatch({ type:'REDIRECT_COMPANY',  payload: false})
            
        })
        .catch((err) => {
        
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                getCompanys()
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch

        })
    }
}

export function deleteCompany(id) {

    return function(dispatch) {
        Api.deleteCompany(id)
        .then((res) => {
            dispatch({ type:'DELETE_COMPANY'})
            dispatch(getCompanys())
            toastr.success("Empresa Excluída Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}

export function showCompany(id){
    
    return dispatch =>{
            Api.showCompany(id)
            .then( res => {
                dispatch({ type:'SHOW_COMPANY', payload: res})
                dispatch({ type:'REDIRECT_COMPANY',  payload: false})
                dispatch(initialize('empresasForm', res.data))
            })
            .catch((err) => {
                const statusCode = err.response.status
                switch (statusCode) {
                  case 401:
                    refreshToken(dispatch)
                    break;
                
                  default:
                    dispatch({  type:'ERRORS', payload: err.response.data})
          
                } // end switch
            })
    }
    
}

export function updatedCompany(empresa) {

    return function(dispatch) {
        Api.updatedCompany(empresa.id_empresa, empresa)
        .then((res) => {
            dispatch({ type:'UPDATED_COMPANY'})
            dispatch(getCompanys())
            dispatch({ type:'REDIRECT_COMPANY',  payload: true})
            toastr.success("Empresa Atualizada Com Sucesso")
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                err.response.data.forEach(error =>toastr.error('Erro', error))
      
            } // end switch
        })
    }

}

