
import Api from './../servicedata/Api'
import refreshToken  from './actionsToken'


export function getInfoDashboard() {

    return function(dispatch) {
        Api.getInfoDashboard()
        .then((res) => {
            dispatch({  type:'GET_INFO', payload: res})
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}


export function getInfoDashboardCliente(id) {

    return function(dispatch) {
        Api.getInfoDashboardCliente(id)
        .then((res) => {
            dispatch({  type:'GET_INFO_CLIENT', payload: res})
        })
        .catch((err) => {
            const statusCode = err.response.status
            switch (statusCode) {
              case 401:
                refreshToken(dispatch)
                break;
            
              default:
                dispatch({  type:'ERRORS', payload: err.response.data})
      
            } // end switch
        })
    }
}


