import React from 'react'
import { Field, reduxForm , formValueSelector} from 'redux-form'
import {connect} from 'react-redux'
import Input   from './../../components/Input'
import Button   from './../../components/Button'
import SelectNivelUser from './../../components/SelectNivelUser'
import 'react-datez/dist/css/react-datez.css'
import ReduxSelectSearch from './../../components/ReduxSelectSearch'
import SelectDeptos from  './../../components/SelectDeptos'


function UsuariosForm(props) {
    
  
    const {handleSubmit, edit} = props
    const options =  filterEmpresas() || [{}]
 

    function filterEmpresas (){
        const empresas = props.data
        if(empresas.length){
          return empresas.map((empresa) => {
                return {value:empresa.id_empresa, label:empresa.razao_social_empresa}  
            })
        } 
     }

     function getEmpresaDefault(){
        const empresas = props.data
        const idEmpresa = props.empresas_id_empresa

        if(empresas.length > 0 && idEmpresa ){
            const current = empresas.filter((empresa) => {
                return empresa.id_empresa === idEmpresa
            })
           return  {value:current[0].id_empresa, label:current[0].razao_social_empresa}  
        } 
       
     }

     function renderSelectEdit(){
        if ( getEmpresaDefault()){
           return(
               <Field 
               name="empresas_id_empresa" 
               options={options} 
               placeholder="Selecione uma empresa" 
               label="Empresa" 
               default={getEmpresaDefault()}
               component={ReduxSelectSearch}/> 
           )
           
        } 
    }

    function renderSelect(){
        return(
            <Field 
            name="empresas_id_empresa" 
            options={options} 
            placeholder="Selecione uma empresa" 
            label="Empresa" 
            component={ReduxSelectSearch}/> 
        )
    }


    function renderSelectDepto(){
        const idEmpresa = getEmpresaDefault()
        const empresas = props.data
        
        if(empresas.length > 0 && idEmpresa ){
            const current = empresas.filter((empresa) => {
                return empresa.id_empresa === idEmpresa.value
            })
           
            const departamentos = current[0].departamentos_empresa
           
            return(
                <Field 
                name="departamentos_id_departamento" 
                options={departamentos} 
                placeholder="Selecione uma empresa" 
                label="Departamento" 
                component={SelectDeptos}/> 
            )

        } 

          //
    }

    renderSelectDepto()
        
        return(
            
        <form name="contactform" className="contactform" onSubmit={handleSubmit}>    
            <div className="row">
                <div className="col-md-6">
                
                    {(edit === "true")? renderSelectEdit() : renderSelect()}
                    {renderSelectDepto()}

                    <Field name="name" label="Nome" component={Input}/>
                    <Field name="email" label="Email" component={Input}/>
                    <Field name="nivel" label="Nível" component={SelectNivelUser}/>
                    <Field name="password" type="password" label="Senha" component={Input}/>
                    <Field name="repeat-password" type="password" label="Repetir Senha" component={Input}/>

                    <Button label={(edit)==="true" ? "Atualizar": "Salvar"}/>    
                    
                 </div>

            </div>
        </form> 
 
        )
  
        
}



const selector = formValueSelector('usuariosForm')

UsuariosForm = connect(
    state => ({
      empresas_id_empresa: selector(state, 'empresas_id_empresa'),
    
    })
  )(UsuariosForm)

export default reduxForm({ form: 'usuariosForm'})(UsuariosForm)



   


