import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import promise from 'redux-promise'
import thunk from 'redux-thunk'
import multi from 'redux-multi'

export default function configureStore() {
 //return createStore(rootReducer, initialState);
  return applyMiddleware(promise, thunk, multi)(createStore)(rootReducer) 
}

