import axios from 'axios'
import BASE_URL from './Environment' 

const instance = axios.create({
   baseURL:   BASE_URL
 });

function getInstance () {
  
   if (sessionStorage.getItem('userData')) {
      const itemStorage = JSON.parse(sessionStorage.getItem('userData'))
      const AUTH_TOKEN = itemStorage.data.token
      instance.defaults.headers.common = {'Authorization': `Bearer ${AUTH_TOKEN}`}

   }
   return instance
 }

const apis ={


   //REFRESH TOKEN =====================================================================    

      refreshToken:()=> getInstance().get(`/refresh`),

    //AUTH =====================================================================    

       login:(user)=>  getInstance().post(`/login`, user),

    //DASHBOARD==================================================================
  
        getInfoDashboard:()=> getInstance().get(`/home`),
        getInfoDashboardCliente:(id)=> getInstance().get(`/home-cliente/${id}`),
  
    //COMPANYS ==================================================================
        
        createCompany:(empresa)=>   getInstance().post(`/empresas`, empresa),    
        getCompanys:()=>  getInstance().get(`/empresas`),
        deleteCompany:(id)=>   getInstance().get(`/empresas/${id}/delete`),
        showCompany:(id)=>   getInstance().get(`/empresas/${id}`),
        updatedCompany :(id, empresa)=>  getInstance().put(`/empresas/${id}`, empresa),

    
     //DEPARTAMENTOS ==================================================================
     
         createDepartamento:(dpto)=>   getInstance().post(`/departamentos`, dpto),    
         getDepartamentos:()=>  getInstance().get(`/departamentos`),
         deleteDepartamento:(id)=> getInstance().get(`/departamentos/${id}/delete`),
         showDepartamento:(id)=>  getInstance().get(`/departamentos/${id}`),
         updatedDepartamento :(id, dpto)=>  getInstance().put(`/departamentos/${id}`, dpto),   
      
    
    //PROTOCOLS ==================================================================

        createProtocol:(empresa)=>   getInstance().post(`/protocolos`, empresa),    
        getProtocols:()=>  getInstance().get(`/protocolos`),
        deleteProtocol:(id)=>  getInstance().get(`/protocolos/${id}/delete`),
        showProtocol:(id)=>   getInstance().get(`/protocolos/${id}`),
        updatedProtocol:(id, protocolo)=>  getInstance().put(`/protocolos/${id}`, protocolo),
        getProtocolsClient:(id)=> getInstance().get(`/protocolos/empresas/${id}`),

     //USUARIOS =====================================================================
         
        createUser:(user)=>   getInstance().post(`/usuarios`, user),   
        getUsers:()=>  getInstance().get(`/usuarios`), 
        showUser:(id)=>   getInstance().get(`/usuarios/${id}`),
        deleteUser:(id)=>   getInstance().get(`/usuarios/${id}/delete`),
        updatedUser:(id, user)=>  getInstance().put(`/usuarios/${id}`, user),
   
     //ARQUIVOS ===================================================================== 
        
        getFiles:()=>  getInstance().get(`/arquivos`), 
        getFilesProtocol :(id)=>  getInstance().get(`/arquivos/protocolos/${id}`), 
        uploadFile:(file, config)=>   getInstance().post(`/arquivos`, file, config),
        deleteFile:(id)=> getInstance().get(`/arquivos/${id}/delete`),
        isAttachment :(id)=>  getInstance().get(`/arquivos/isattachment/${id}`), 
       
       
}

export default apis