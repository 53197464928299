import React, {Component} from 'react'
import MainLayout   from './../../../components/MainLayout'
import {connect} from 'react-redux'
import {bindActionCreators}  from 'redux'
import PerfilClienteForm from './PerfilClienteForm';
import {showProfileClient, updatedUser} from './../../../actions/actionsUsuarios'
import {getCompanys} from './../../../actions/actionsEmpresas'

class PerfilCliente extends Component {

    componentDidMount(){
        this.props.getCompanys()
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        this.props.showProfileClient(userData.data.user.id)
    }

    render(){
    
        const empresas = this.props.empresas.data.data || []
      
   
        return(
            <MainLayout title="Perfil">
                <PerfilClienteForm data={empresas} onSubmit={this.props.updatedUser}/>    
            </MainLayout>
        )
    }    
}


function mapStateToProps(state){
    return {
        usuarios: state.user,
        empresas: state.empresa,
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ showProfileClient, updatedUser, getCompanys}, dispatch)
}

export default connect(mapStateToProps,  mapDispatchToProps)(PerfilCliente)