import React from 'react'

const FormGroup = (props)=>(
 
    <div className="form-group">
        <label className="col-sm-12">{props.label}</label>
            <div className="col-sm-12"> 
                    {props.children}
            </div>
    </div>
    
) 

export default FormGroup

